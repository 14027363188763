ion-tab-bar {
  overflow: auto;
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 55px;
  bottom: 4px;
  background-color: var(--ion-color-medium);
  padding: 5px;
  border-radius: 5px;
  color: var(--ion-color-medium-contrast);
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
}

html input[disabled], .select-disabled, textarea[disabled]{
/*  color: #3880ff !important;*/
  color: var(--ion-color-primary) !important;
  opacity: 1 !important;
}

@media print {
  body { 
    height: 500% !important;
    min-height: 100%;
    max-height: unset !important;
  }
  .no-print { display: none; }
  .print { display: block !important; }
  .printModal {
    --overflow: visible !important;
    --height: auto !important;
    --min-height: 100%;
    --max-height: unset !important;
    --width: 100%;
  }
}

h3 {
  color: var(--ion-color-medium);
}

ion-segment-button ion-badge {
  position: absolute;
  padding-top: 3px;
  padding-bottom: 2px;
  left: calc(50% + 6px);
  top: 8px;
  min-width: 12px;
  font-size: 8px;
  font-weight: 400;
  border-radius: 8px;
}

.borderblue {
  border-color: var(--ion-color-secondary) !important;
  box-shadow: 1px 2px 2px 0px var(--ion-color-secondary-shade) !important;
}

.borderwarning {
  border-color: var(--ion-color-warning) !important;
  box-shadow: 1px 2px 2px 0px var(--ion-color-warning-shade) !important;
}

.logo {
  padding: 20px;
}

.loginB {
  position: fixed;
  left: 33px;
  top: 10px;
  z-index: 1;
}

.quantity {
  text-align: center;
  width: 50%;
  float: left;
}
.price {
  text-align: center;
  width: 50%;
  float: right;
}
.label {
  font-size: xx-small;
  color: var(--ion-color-medium);
}
.status {
  text-align: right;
  min-width: 10%;
  float: right;
}
.available {
  color: var(--ion-color-success) !important;
}
.risk {
  color: var(--ion-color-warning) !important;
}
.warning {
  color: var(--ion-color-danger) !important;
}
.floatright{
  position: relative;
  float: right;
  margin-top: -2.8em;
  z-index: 2;
}
.show{
  display: block !important;
}
.hidden{
  display: none;
}
.placeholder {
  height: 100%;
  color: var(--ion-color-medium);
  display: flex;
  align-items: center;
  justify-content: center;
}

.printlabel {
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: large;
  width: 480px !important;
  max-width: 480px !important;
}

.smallerx {
  font-size: x-small;
  color: var(--ion-color-tertiary);
}

.smallerx2 {
  font-size: x-small;
  color: var(--ion-color-warning);
}

.smaller {
  font-size: smaller;
}

.small {
  font-size: small;
}

.larger {
  font-size: larger;
}

.largerX {
  font-size: x-large !important;
}

.large {
  font-size: xx-large;
}

.bold {
  font-weight: bold;
}

.border {
  border: 1px solid var(--ion-color-light);
}

.bordertop {
  border-top: 2px solid black;
  margin-top: 10px;
  padding-top: 10px;
}

.borderbottom {
  border-bottom: 2px solid black;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.box {
  border: 2px solid black;
  margin: 10px 10px 10px 0;
}

.paddingtb {
  padding-bottom: 10px;
  padding-top: 10px;
}

.paddingt {
  padding-top: 10px;
}

.paddinglr {
  padding-left: 10px;
  padding-right: 10px;
}

.marginlr {
  margin-left: 10px;
  margin-right: 3px;
}

.marginb {
  margin-bottom: 5px;
}

.marginl {
  margin-left: 5px !important;
}

.margint {
  margin-top: 5px;
}

.margint10 {
  margin-top: 10px;
}

.marginr{
  margin-right: 5px;
}

.fit {
  flex: 0 0 100%;
}

.fixed {
  position: fixed;
}

.clearboth {
  clear: both;
}

.item-input .sc-ion-label-md-h {
  width: 100%;
  max-width: 100% !important;
  flex: auto;
}

.margin10 {
  margin: 10px;
}

.padding10 {
  padding: 10px;
}

.rotate90 {
  -ms-transform: rotate(90deg); /* IE 9 */
  transform: rotate(90deg);
}

.rotate180 {
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}

.rotate270 {
  -ms-transform: rotate(270deg); /* IE 9 */
  transform: rotate(270deg);
}

.catalog {
  --border-radius: 0;
  height: 80px;
  width: 80px;
  text-align:center;
}

.catalog img {
  width: auto;
}

.catalogbg {
  --border-radius: 10px;
  --background: var(--ion-color-light);
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
  --padding-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fulltext {
  white-space: pre-wrap !important;
}

.info {
  font-size: small;
  color: var(--ion-color-warning);
}

.TitleBar {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.DangerBar {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.AvailableBar {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

.passive {
  color: var(--ion-color-tertiary) !important;
}

.paidtext {
  color: var(--ion-color-primary);
}